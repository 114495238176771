<template>
    <footer class="vz-footer">
        <div class="legal">
            <router-link to="/privacy">
                {{ $t('privacy-policy.title') }}
            </router-link>
            <span>•</span>
            <router-link to="/disclaimer">
                {{ $t('disclaimer.title') }}
            </router-link>
            <span>•</span>
            <router-link to="/contact">
                {{ $t('contact.title') }}
            </router-link>
            <!-- <span v-if="loggedIn">•</span>
            <router-link to="/" v-if="loggedIn">
                <material variant="empty">info</material>
            </router-link>
            <span v-if="loggedIn">•</span>
            <router-link to="/" v-if="loggedIn" @click="resetOnboarding">
                <material variant="empty">help</material>
            </router-link> -->
        </div>
        <div class="version">
            <vz-version :copyright="$t('general.company')" :prefix="$t('general.version-short')" />
        </div>
    </footer>
</template>

<script setup lang="ts">
    import type { User } from '~/types';

    const { user, clear } = useUserSession();
    const loggedIn = computed(() => (user.value as User)?.name != undefined);

    const resetOnboarding = () => {
        // eslint-disable-next-line no-console
        document.cookie = 'onboarding=false;';
        window.location.reload();
    };
</script>

<style scoped lang="scss">
    .vz-footer {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        height: min-content;
        padding: 1rem;

        @include breakpoint(medium) {
            padding-inline: 2rem;
        }

        .legal,
        .version {
            align-items: center;
            display: flex;
            flex-flow: row wrap;
            gap: 0.5rem;
            width: 100%;
        }
    }
</style>
