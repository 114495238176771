<template>
    <div class="vz-version">
        <template v-if="copyright">
            &copy; {{ new Date().getFullYear() }} {{ copyright }}.
        </template>
        {{ prefix }}{{ version }}
    </div>
</template>

<script setup lang="ts">
    import { version } from '@/package.json';

    type Props = {
        prefix?: string;
    copyright?: string;
    };

    const props = defineProps<Props>();

    const prefix = computed(() => (props.prefix ? props.prefix : ''));
</script>

<style scoped lang="scss">
    .vz-version {
        font-size: 0.75rem;
    }
</style>
